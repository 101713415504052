import React from "react";
import './Faq.scss';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>FAQ | San Bernardino Air Duct Cleaning - Your Air Duct Service Questions Answered</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="FAQ | San Bernardino Air Duct Cleaning - Your Air Duct Service Questions Answered" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="FAQ | San Bernardino Air Duct Cleaning - Your Air Duct Service Questions Answered" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.sanbernardinoairduct.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="EFind answers to common questions about Air Duct care, inspections, cleanings, and repairs. Explore our FAQ page for expert advice and insights from San Bernardino Air Duct Cleaning." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="EFind answers to common questions about Air Duct care, inspections, cleanings, and repairs. Explore our FAQ page for expert advice and insights from San Bernardino Air Duct Cleaning." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="FAQ | San Bernardino Air Duct Cleaning - Your Air Duct Service Questions Answered" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Residential Air Duct Cleaning, Residential Air Duct Cleaning, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Residential Air Duct Cleaning, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Residential Air Duct Cleaning, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rain Cap Installation, Air Duct Cleaning, Air Duct Inspection, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Dryer Vent Cleaning, Air Duct Damper Repair, Air Duct Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Air Duct Cleaning FAQs</h1>
                <h2>For expert Air Duct Cleaning services, reach out to San Bernardino Air Duct Cleaning. Our certified technicians are committed to delivering reliable, high-quality repairs to ensure your Air Duct remains safe and fully functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#air-duct-cleaning">Air Duct Cleaning</a>
                    <a href="/faq#residential-air-duct-cleaning">Residential Air Duct Cleaning</a>
                    <a href="/faq#air-duct-maintenance">Air Duct Maintenance</a>
                    <a href="/faq#hvac-system-cleaning">Air Duct Cleaning</a>
                    <a href="/faq#dryer-vent-cleaning">Dryer Vent Cleaning</a>
                    <a href="/faq#air-duct-inspection">Air Duct Inspection</a>
                    <a href="/faq#furnace-duct-cleaning">Furnace Duct Cleaning</a>
                </div>
                <a className="BlogAsideCall" href="tel:760-239-5357">CALL 760-239-5357<img src={telephone} alt="Air Duct Cleaning In San Bernardino, CA | Air Duct Maintenance In San Bernardino, CA | AC Duct Cleaning In San Bernardino, CA | Dryer Vent Cleaning In San Bernardino, CA" /></a>
                <a className="BlogAsideCall" href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning In San Bernardino, CA | Air Duct Maintenance In San Bernardino, CA | AC Duct Cleaning In San Bernardino, CA | Dryer Vent Cleaning In San Bernardino, CA" /></a>


            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="Air Duct Cleaning In San Bernardino, CA | Air Duct Maintenance In San Bernardino, CA | AC Duct Cleaning In San Bernardino, CA | Dryer Vent Cleaning In San Bernardino, CA" />
                                    <h2>San Bernardino Air Duct Cleaning promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional Air Duct Cleaning services, contact San Bernardino Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Air Duct safe and functional.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="air-duct-cleaning">Air Duct Cleaning</h6>
                        {faqlistFilter.filter(a => a.category == "Air Duct Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="residential-air-duct-cleaning">Residential Air Duct Cleaning</h6>
                        {FaqList.filter(a => a.category == "Residential Air Duct Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="air-duct-maintenance">Air Duct Maintenance</h6>
                        {FaqList.filter(a => a.category == "Air Duct Maintenance").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="hvac-system-cleaning">HVAC System Cleaning</h6>
                        {FaqList.filter(a => a.category == "HVAC System Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="dryer-vent-cleaning">Dryer Vent Cleaning</h6>
                        {FaqList.filter(a => a.category == "Dryer Vent Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="air-duct-inspection">Air Duct Inspection</h6>
                        {FaqList.filter(a => a.category == "Residential Air Duct Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="furnace-duct-cleaning">Furnace Duct Cleaning</h6>
                        {FaqList.filter(a => a.category == "Furnace Duct Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>
                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#air-duct-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#air-duct-cleaning">Air Duct Cleaning</a>
                        <a style={location.hash == "#residential-air-duct-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#residential-air-duct-cleaning">Residential Air Duct Cleaning</a>
                        <a style={location.hash == "#air-duct-maintenance" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#air-duct-maintenance">Air Duct Maintenance</a>
                        <a style={location.hash == "#hvac-system-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#hvac-system-cleaning">HVAC System Cleaning</a>
                        <a style={location.hash == "#dryer-vent-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#dryer-vent-cleaning">Dryer Vent Cleaning</a>
                        <a style={location.hash == "#air-duct-inspection" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#air-duct-inspection">Air Duct Inspection</a>
                        <a style={location.hash == "#furnace-duct-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#furnace-duct-cleaning">Furnace Duct Cleaning</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="Air Duct Cleaning In San Bernardino, CA | Air Duct Maintenance In San Bernardino, CA | AC Duct Cleaning In San Bernardino, CA | Dryer Vent Cleaning In San Bernardino, CA" />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>


            <NavigatorPath />

        </div >
    )
}

